
                                    $static-content-cache-base-url: "https://s3-ap-southeast-2.amazonaws.com/prod-automait-public-website-content";
                                    $cloud-front-base-url: "https://dr1k2g3wmnols.cloudfront.net";
                                
//===================================================
// Suttons
//===================================================
@import '../../core';

// Colours
$color-theme-palette: rgb(224, 229, 232) rgb(179, 190, 197) rgb(128, 147, 159) rgb(77, 104, 120) rgb(38, 71, 91) rgb(0, 39, 62) rgb(0, 35, 56) rgb(0, 29, 48) rgb(0, 23, 40) rgb(0, 14, 27);

$color-theme-50: nth($color-theme-palette, 1);
$color-theme-100: nth($color-theme-palette, 2);
$color-theme-200: nth($color-theme-palette, 3);
$color-theme-hover: nth($color-theme-palette, 4);
$color-theme-400: nth($color-theme-palette, 5);
$color-theme: nth($color-theme-palette, 6);
$color-theme-600: nth($color-theme-palette, 7);
$color-theme-700: nth($color-theme-palette, 8);
$color-button--hover: nth($color-theme-palette, 9);
$color-theme-900: nth($color-theme-palette, 10);

$color-theme--darker: darken($color-theme, 4%);
$color-ribbon: #E50C5A;

//===================================================
//Main nav themes
$nav-color: $color-white;
$nav-mobile-color: $color-theme;
$nav-title-color: $color-white;
$nav-bg-color: $color-theme;
$nav-br-color: $color-theme;
$nav-active-br-color: $color-theme-400;
$nav-toggle-bg-color: $color-theme-400;
$nav-hover-color: $color-theme;
$nav-hover-bg-color: $color-white;
$logos-bar-height: 0;

//Contact Nav themes
$contact-nav-bg-color: $color-white;
$contact-nav-color: $color-black;
$contact-nav-br-color: $color-white;

//Sub Nav themes
$sub-nav-bg-color: $color-theme;
$sub-nav-br-color: $color-theme-400;
$sub-nav-hover-color: $color-white;
$sub-nav-hover-bg-color: $color-button--hover;
$sub-nav-title-color: $color-white;
$sub-nav-desc-color: $color-grey;
$sub-nav-carousel-theme-item-color: $color-grey;
$sub-nav-carousel-theme-hover-item-color: $color-white;
$sub-nav-mobile-active-title-color: $color-theme;

//Colour for headers across the site (.header--theme-color)
$header-color: $color-theme;

//Colours for cards
$card-header-color: $header-color;
$card-price-color: $color-black;
$card-icon-color: $header-color;

//Colours for the Main header on content pages
$header-title-color: $color-theme;
$header-sub-title-color: $color-theme;
$header-gradient-color-left: $color-theme-50;
$header-gradient-color-right: $color-theme-100;

//Colours for the VDP page header
$header-VDP-title-color: $color-theme;
$header-VDP-gradient-color-left: $color-theme-50;
$header-VDP-gradient-color-right: $color-theme-100;

// Main Button
$color-button: #ff9c22;
$color-button--hover: #F5931F;
$color-button-text: $color-white;

// Colour for the sticky footer elements
$sticky-footer-background-color: $color-theme;
$form-header-color: $color-theme-600;

// Panels
$panel-background-color: $color-theme;

@import "../../../src/application";